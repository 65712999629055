import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/newvini.scss";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import vino from "../images/vino.jpg";
import "react-medium-image-zoom/dist/styles.css";
import Globals from "../parameter/globals";
import { Helmet } from "react-helmet";

function NewVini(props) {
  const seeMore = (key) => {
    setShowMore(!showMore);
    setDingleEl(key);
  };
  const [singleEl, setDingleEl] = useState();

  const [showMore, setShowMore] = useState(true);
  const parse = require("html-react-parser");
  const [selected, updateSelect] = useState("");
  const [item, setItem] = useState();

  const menuItems = [...new Set(props.viniTot.map((Val) => Val))];

  const filterItem = (curcat) => {
    // scrollTo();

    const portfolio = props.vini.map((num) => num);
    const newItem = portfolio.filter((newVal) => {
      return newVal.nome_categoria_it.toString() === curcat.toString();
    });
    setItem(newItem);
    updateSelect(true);
  };
  // const scrollTo = (ref) => {
  //   const section = document.querySelector( '#vino' );

  //   section.scrollIntoView( { behavior: 'smooth'} );
  //   const qua = document.querySelector( '#qua' );
  //   qua.scrollIntoView( { behavior: 'smooth', block: "end"} );

  // }
  return (
    <div className="App" id="vino">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vino - Gravner</title>
        <link rel="canonical" href="https://www.gravner.it/#vino" />
      </Helmet>
      <div
        id="qua"
        className="d-flex justify-content-center py-3 scroll-color-colors px-5 mx-auto sticky responsive-button-wine"
        style={{
          backgroundColor: parseInt(selected) ? "#e9ecf4" : "#fff",
        }}
      >
        {menuItems.map((Val, key) => {
          return (
            <button
              key={key}
              // onClick={onChange}
              onClick={() => filterItem(Val.nome_categoria_it)}
              className={
                selected === Val.nome_categoria_it
                  ? "btn text-dark p-1 w-25 mx-1 fw-bold btn btn-lg btn-resp"
                  : "btn text-light p-1 w-25 mx-1 fw-bold btn btn-lg btn-resp"
              }
              style={{
                backgroundColor:
                  selected === Val.nome_categoria_it
                    ? "#fff"
                    : "#" + Val.background_color_hex,
                border:
                  selected === Val.nome_categoria_it ? "1px solid #fab700" : "",
              }}
              value={Val}
            >
              {props.lang === "it"
                ? Val.nome_categoria_it
                : props.lang === "en"
                ? Val.nome_categoria_en
                : Val.nome_categoria_si}
            </button>
          );
        })}
        {/* <button
          className="btn text-white p-1 w-25 mx-1 fw-bold btn btn-lg btn-resp"
          style={{ backgroundColor: "#fab700" }}
        >
          Ribolla{" "}
        </button>
        <button
          className="btn text-white p-1 w-25 mx-1 fw-bold btn btn-lg btn-resp"
          style={{ backgroundColor: "#c31b1c" }}
        >
          Rosso Gravner{" "}
        </button>
        <button
          className="btn text-white p-1 w-25 mx-1 fw-bold btn btn-lg btn-resp"
          style={{ backgroundColor: "#c31b1c" }}
        >
          Rosso Breg{" "}
        </button>
        <button
          className="btn text-white p-1 w-25 mx-1 fw-bold btn btn-lg btn-resp"
          style={{ backgroundColor: "#eb7a00" }}
        >
          Riserva{" "}
        </button> */}
      </div>
      <div
        className="d-flex justify-content-center  container-fluid"
        style={{ backgroundColor: "#e9ecf4" }}
      >
        {selected !== "" ? (
          <div>
            <Row xs={1} lg={3} md={2} sm={1} className="g-3 py-3">
              {item.map((num, key) => {
                return (
                  <div>
                    <Col>
                      <Card
                        style={{ width: "100%", backgroundColor: "#fff" }}
                        className="mb-0 mx-auto card-resp"
                      >
                        <Card.Title className="text-dark py-2">
                          <strong>
                            {" "}
                            {props.lang === "it"
                              ? num.nome_vino_it
                              : props.lang === "en"
                              ? num.nome_vino_en
                              : num.nome_vino_si}
                          </strong>
                        </Card.Title>

                        <Card.Body>
                          <div className="d-flex responsive-wine-div-image">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              {/* <Zoom> */}
                              <Card.Img
                                className="image-wine"
                                style={{
                                  width: "100%",
                                  height: "350px",
                                  objectFit: "contain",
                                }}
                                variant="top"
                                src={
                                  num.path_immagine !== undefined
                                    ? Globals.domain + num.path_immagine
                                    : Globals.domain + num.categorie_img_default
                                }
                              />

                              {/* </Zoom> */}
                              {/* position:"absolute",
                            left:"-160px",
                            bottom:"0" */}
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 text-dark my-auto align-content-center">
                              <strong>
                                {Globals.language.vine.category[props.lang]} :
                              </strong>{" "}
                              {props.lang === "it"
                                ? num.nome_categoria_it
                                : props.lang === "en"
                                ? num.nome_categoria_en
                                : num.nome_categoria_si}
                              <span className="mx-2">|</span>
                              <strong>
                                {Globals.language.vine.vintage[props.lang]} :
                              </strong>{" "}
                              {num.anno}
                              <br></br>
                              {/* <strong>Bianco Breg 2012</strong> */}
                              <br></br>
                              <Card.Text className="text-dark mx-2" key={key}>
                                {showMore && singleEl === key
                                  ? parse(
                                      props.lang === "it"
                                        ? num.testo_vino_it
                                        : props.lang === "en"
                                        ? num.testo_vino_en
                                        : num.testo_vino_si
                                    )
                                  : parse(
                                      props.lang === "it"
                                        ? num.testo_vino_it.substring(0, 250)
                                        : props.lang === "en"
                                        ? num.testo_vino_en.substring(0, 250)
                                        : num.testo_vino_si.substring(0, 250)
                                    )}
                                {num.testo_vino_it.substring(0, 250).length <
                                250 ? (
                                  <></>
                                ) : (
                                  <button
                                    key={key}
                                    onClick={() => seeMore(key)}
                                    className="btn btn-light text-decoration-underline"
                                  >
                                    {showMore && singleEl === key
                                      ? Globals.language.vine.showless[
                                          props.lang
                                        ]
                                      : Globals.language.vine.showmore[
                                          props.lang
                                        ]}
                                  </button>
                                )}
                                <hr></hr>
                                {parseInt(num.attachments.length) !== 0 ? (
                                  <div>
                                    <a
                                      href={Globals.domain + num.attachments}
                                      target="_blank"
                                      className="btn btn-warning text-light w-100"
                                      rel="noreferrer"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {num.title}
                                    </a>{" "}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Card.Text>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </div>
                );
              })}
            </Row>
          </div>
        ) : (
          <div>
            <img width={"100%"} src={vino} alt="vini"></img>
          </div>
        )}
      </div>
    </div>
  );
  // }
}

export default NewVini;
