import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/vendemmia.scss";
import { browserName, browserVersion } from "react-device-detect";
import { Helmet } from "react-helmet";

function Vendemmia(props) {
  const parse = require("html-react-parser");
  return (
    <div className="App py-5" id="vendemmie">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vendemmie - Gravner</title>
        <link rel="canonical" href="#vendemmie" />
      </Helmet>
      {props.images?.map((item) => {
        return (
          <div
            className={
              `${browserName}` === "Mobile Safari" ? "flip-safari" : "flip"
            }
          >
            <div
              className="front"
              style={{ backgroundColor: "#" + item.background_color_hex }}
            >
              <h1 className="text-shadow">{item.anno}</h1>
            </div>
            <div
              className="back"
              style={{ backgroundColor: "#" + item.background_color_hex }}
            >
              <h2>{item.anno}</h2>
              <p>
                {parse(
                  props.lang === "it"
                    ? item.testo_it
                    : props.lang === "en"
                    ? item.testo_en
                    : item.testo_si
                )}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
  // }
}

export default Vendemmia;
