import Navbar from "./components/Navbar";
import NavbarTest from "./components/NavbarTest";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Homepage";
import ErrorPage from "./pages/ErrorPage";
import Contact from "./pages/Contact";
import MeetUs from "./pages/MeetUs";
import Punti from "./pages/Punti";
import Vini from "./pages/Vini.js";
import { ReCaptchaProvider } from "react-recaptcha-x";
import Globals from "./parameter/globals";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermOfUse from "./pages/TermOfUse";
import ContactTermOfUse from "./pages/ContactTermOfUse";
import Credits from "./pages/Credits";
import Cookiebar from "./components/Cookiebar";

function modifyCookie() {
  document.getElementById("container").style.display = "block";
}

function App() {
  return (
    <>
      <ReCaptchaProvider
        siteKeyV2={Globals.recaptcha_v2_key}
        langCode="it"
        hideV3Badge={false}
      >
        <Navbar />
        {/* <NavbarTest/> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="contatti" element={<Contact />} />
          <Route path="incontrarci" element={<MeetUs />} />
          <Route path="punti-g" element={<Punti />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="term-of-use" element={<TermOfUse />} />
          <Route path="contact-term-of-use" element={<ContactTermOfUse />} /> 
          <Route path="credits" element={<Credits />} /> 
          <Route path="it/" element={<Home />} />
          <Route path="it/home" element={<Home />} />
          <Route path="it/contatti" element={<Contact />} />
          <Route path="it/incontrarci" element={<MeetUs />} />
          <Route path="it/punti-g" element={<Punti />} />
          <Route path="it/privacy" element={<PrivacyPolicy />} />
          <Route path="it/term-of-use" element={<TermOfUse />} />
          <Route path="it/contact-term-of-use" element={<ContactTermOfUse />} />
           
          <Route path="it/vini" element={<Vini />}>
            <Route path=":id/:slugurl" element={<Vini />} />
          </Route>
          <Route path="it/credits" element={<Credits />} /> 
          <Route path="en/" element={<Home />} />
          <Route path="en/home" element={<Home />} />
          <Route path="en/contatti" element={<Contact />} />
          <Route path="en/incontrarci" element={<MeetUs />} />
          <Route path="en/punti-g" element={<Punti />} />
          <Route path="en/privacy" element={<PrivacyPolicy />} />
          <Route path="en/term-of-use" element={<TermOfUse />} />
          <Route path="en/contact-term-of-use" element={<ContactTermOfUse />} />
           
          <Route path="en/vini" element={<Vini />}>
            <Route path=":id/:slugurl" element={<Vini />} />
          </Route>
          <Route path="en/credits" element={<Credits />} /> 
          <Route path="si/" element={<Home />} />
          <Route path="si/home" element={<Home />} />
          <Route path="si/contatti" element={<Contact />} />
          <Route path="si/incontrarci" element={<MeetUs />} />
          <Route path="si/punti-g" element={<Punti />} />
          <Route path="si/privacy" element={<PrivacyPolicy />} />
          <Route path="si/term-of-use" element={<TermOfUse />} />
          <Route path="si/contact-term-of-use" element={<ContactTermOfUse />} />
           
          <Route path="si/vini" element={<Vini />}>
            <Route path=":id/:slugurl" element={<Vini />} />
          </Route>
          <Route path="si/credits" element={<Credits />} /> 
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Cookiebar />
        <Footer modifyCookie={modifyCookie.bind()} />
      </ReCaptchaProvider>
    </>
  );
}

export default App;
