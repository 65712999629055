import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../parameter/globals";
import { Helmet } from "react-helmet";
import GeneralContext from "../context/GeneralContext";
function Credits() {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [credits, setCredits] = useState([]);
	const { lang } = useContext(GeneralContext);

	useEffect(() => {
		getPageCredits();
	}, [lang]);

	const getPageCredits = async () => {

        setError(false);
        setMessage("");


		try {
			const response = await axios.get(Globals.GETCREDITS, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setCredits(
					lang === "it"
						? response?.data?.credit.htmlcontents_it
						: lang === "en"
						? response?.data?.credit.htmlcontents_en
						: response?.data?.credit.htmlcontents_si
				);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};
    

	return (
		<div>
		 {error ? (
         <section className='text-center'>
             {" "}
             <h2> {message}</h2>
       </section>
     ) : (
         <div
             className='container'
             style={{ marginTop: "140px", marginBottom: "100px" }}>
             <Helmet>
                 <meta charSet='utf-8' />
                 {/* <title>{Globals.language.title.privacyPolicy[lang]}</title> */}
                 <link rel='canonical' href='https://www.gravner.it/credits' />
				 <meta name="robots" content="noindex" />

             </Helmet>
             <div dangerouslySetInnerHTML={{ __html: credits }} />
         </div>
     )}
		</div>
	);
}

export default Credits;
