import { ThreeDots } from "react-loader-spinner";
import "../scss/loading.scss";
import loader from "../images/logo512.png";

import React from "react";

function Loading() {
  return (
    <article
      style={{
        textAlign: "center",
        display: "flex",
        height: "50em",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="dl">
        <div className="dl__container">
          <div className="dl__corner--top"></div>
          <div className="dl__corner--bottom"></div>
        </div>
        <div className="dl__square">
          <img src={loader} width="50px"></img>
        </div>
      </div>
    </article>

/* <ThreeDots
color='#cd3426'
ariaLabel='three-dots-loading'
wrapperStyle={{
	width: "80px",
}}
wrapperclassNameName='loader'
visible={true}
/> */
    // 	<div className="loading">
    // 	<span classNameName="pos text-dark">Gravner</span>
    //   </div>
  );
}

export default Loading;
