import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/terra.scss";
import Carousel from "react-bootstrap/Carousel";
import ReactPlayer from "react-player";
import Globals from "../parameter/globals";
import { Helmet } from "react-helmet";

function Terra(props) {
  const parse = require("html-react-parser");

  return (
    <div className="App" id="terra">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terra - Gravner</title>
        <link rel="canonical" href="#terra" />
      </Helmet>
      <Carousel pause={false} className="py-5">
        {props.images?.map((item) => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100"
                style={{ height: "700px", objectFit: "cover" }}
                src={Globals.domain + item.path_immagine}
                alt="Land"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div>
        {props.contents.htmlcontents_it !== undefined
          ? props.lang === "it"
            ? parse(props.contents.htmlcontents_it)
            : props.lang === "en"
            ? parse(props.contents.htmlcontents_en)
            : parse(props.contents.htmlcontents_si)
          : ""}
      </div>
      <div
        className="d-flex justify-content-center text-center mx-auto py-2 "
        style={{ backgroundColor: "#f5f6fa" }}
      >
        <ReactPlayer
          className="react-player"
          controls={true}
          width={"50%"}
          height={"450px"}
          url={
            "https://www.youtube.com/watch?v=" +
            (props.lang === "it"
              ? props.contents.youtube_id_it
              : props.contents.youtube_id_en)
          }
          config={{
            youtube: {
              playerVars: { showinfo: 0 },
            },
          }}
        />
      </div>
    </div>
  );
  // }
}

export default Terra;
