import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/case.scss";
import Carousel from "react-bootstrap/Carousel";
import Globals from "../parameter/globals";

import casa from "../images/case/lacasa.jpg";
import { Helmet } from "react-helmet";

function Case(props) {
  const parse = require("html-react-parser");

  return (
    <div className="App" id="case">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Case - Gravner</title>
        <link rel="canonical" href="#case" />
      </Helmet>
      <Carousel pause={false} className="py-5">
        {props.images?.map((item) => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100"
                style={{ height: "700px", objectFit: "cover" }}
                src={Globals.domain + item.path_immagine}
                alt="House"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div>
        {props.contents.htmlcontents_it !== undefined
          ? props.lang === "it"
            ? parse(props.contents.htmlcontents_it)
            : props.lang === "en"
            ? parse(props.contents.htmlcontents_en)
            : parse(props.contents.htmlcontents_si)
          : ""}
      </div>
      <div>
        <img className="img-fix" src={casa} alt="Casa"></img>
      </div>
    </div>
  );
  // }
}

export default Case;
