//url da modificare in fase di deploy online
let baseUrl = "https://cms.gravner.it/webservices/1.0/";
let domain = "https://cms.gravner.it/";
let recaptcha_v2_key = process.env.REACT_APP_RECAPTCHA_PRODUCTION_KEY;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	baseUrl = "https://cmsgravner.readynet.it/webservices/1.0/";
	domain = "https://cmsgravner.readynet.it/";
	recaptcha_v2_key = process.env.REACT_APP_RECAPTCHA_DEV_KEY;
}

const Globals = {
	baseUrl: baseUrl,
	domain: domain,
	recaptcha_v2_key: recaptcha_v2_key,

	GETMOONPHASES: baseUrl + "?a=get_moon_phases",
	GETHOMEDATA: baseUrl + "?a=get_home_contents",
	GENERALCONTACT: baseUrl + "?a=general_contact",
	MEETUSCONTACT: baseUrl + "?a=meetus_contact",
	GETEXTMEETUS: baseUrl + "?a=get_meetus_text",
	GETPUNTIG: baseUrl + "?a=get_punti_g",
	GETVINO: baseUrl + "?a=get_vino",
	GETLEGALPAGE: baseUrl + "?a=get_legal_page",
	GETERMOFUSE: baseUrl + "?a=get_term_of_use",
	GETCONTACTERMOFUSE : baseUrl + "?a=get_contact_term_of_use",
	GETCREDITS : baseUrl + "?a=get_credits_page",
	GETPREFERENCECOOKIE: baseUrl + "?a=getPreferencesCookie",
	SAVEPREFERENCECOOKIE: baseUrl + "?a=saveCookiePreferences",
	GETFOOTERCOOKIE: baseUrl + "?a=get_footer_cookie",





	language: {
		contacts: {
			titleCustomers: {
				it: "Contattaci",
				en: "Contact us",
				si: "",
			},
			sendSuccess: {
				it: "Contatto inviato con successo !",
				en: "Contact sent successfully !",
				si: "stik je bil uspešno poslan!",
			},
			sendTextSuccess: {
				it: "Grazie per la richiesta. Verrà ricontattato entro breve.",
				en: "Thank you for your request. You will be contacted shortly",
				si: "Zahvaljujemo se vam za vašo zahtevo. V kratkem vas bomo kontaktirali",
			},
			nameForm: {
				it: "Nome",
				en: "First Name",
				si: "Ime",
			},
			nameFormPlaceholder: {
				it: "Inserisci il nome",
				en: "Enter first name",
				si: "",
			},
			surnameForm: {
				it: "Cognome",
				en: "Last name",
				si: "Priimek",
			},
			surnameFormPlaceholder: {
				it: "Inserisci il cognome",
				en: "Enter last name",
				si: "",
			},
			emailForm: {
				it: "Email",
				en: "Email",
				si: "E-pošta",
			},
			emailFormPlaceholder: {
				it: "Inserisci email",
				en: "Enter email",
				si: "",
			},
			phoneForm: {
				it: "Numero di telefono",
				en: "Phone number",
				si: "Mobitel",
			},
			phoneFormPlaceholder: {
				it: "Inserisci il numero di telefono",
				en: "Enter phone number",
				si: "",
			},
			messaggeForm: {
				it: "Messaggio",
				en: "Message",
				si: "Sporočilo",
			},
			messaggeFormPlaceholder: {
				it: "Inserisci il testo del messaggio",
				en: "Enter the text of the message",
				si: "",
			},
			privacyForm: {
				it: "Autorizzo il trattamento dei dati personali in base alla ",
				en: "I authorize the processing of data in accordance with the  ",
				si: "Dovoljujem uporabo osebnih podatkov v skladu z ",
			},
			privacyLabel:{
				it: "privacy contatti",
				en: "privacy contacts .",
				si: "privacy contacts",
			},
			sendButtonForm: {
				it: "Invia",
				en: "Send",
				si: "Pošlji",
			},
			phone: {
				it: "Telefono:",
				en: "Phone number:",
				si: "",
			},
			email: {
				it: "Email:",
				en: "Email:",
				si: "",
			},
			contactInvitation: {
				it: "Per informazioni non esitare a contattarci.",
				en: "Do not hesitate to contact us for additional information",
				si: "Pišite nam za morebitne dodatne informacije",
			},
			data: {
				it: "Data",
				en: "Date",
				si: "Datum",
			},
			timeSlot: {
				it: "Scegli una fascia oraria",
				en: "Choose a time slot",
				si: "Izberi uro",
			},
			morning:{
				it: "Mattina",
				en: "Morning",
				si: "Zjutraj",
			},
			afternoon:{
				it: "Pomeriggio",
				en: "Afternoon",
				si: "Popoldan",
			},
			visitorsNumber:{
				it: "Numero persone",
				en: "Number of visitors ",
				si: "število oseb",
			},
			languagePreference:{
				it: "Lingua in cui è richiesta la visita",
				en: "Language preference for the visit ",
				si: "jezik, v katerem želite, da se obisk izvede",
			}

			
		},

		errorLabel: {
			errorPagelabel: {
				it: "Ci dispiace, ma la pagina cercata non esiste!",
				en: "Sorry, but the page you are looking for does not exist!",
			},
			comeBackHome: {
				it: "Torna alla home!",
				en: "Return to home!",
			},
			doLogin: {
				it: "É necessario effettuare il login per effettuare un pagamento",
				en: "You must login to make a payment",
			},
			noSelectCongress: {
				it: "Non è stato selezionato alcun congresso/evento",
				en: "No congresses/events selected",
			},
			noAuth: {
				it: "Non sei autenticato per poter eseguire l'ordine",
				en: "You are not authenticated to submit your order",
			},
			serverError: {
				it: "Errore di comunicazione con il server",
				en: "Communication error with the server",
			},
			errorPaymentInfo: {
				it: "Errore nel recupero dei dati di pagamento",
				en: "Error in retrieving payment data",
			},
			acceptRecaptcha: {
				it: "Accettare il recaptcha per poter eseguire la form di contatto",
				en: "Accept recaptcha in order to run the contact form",
			},
			recaptchaExpired: {
				it: "É necessario riaccettare il checkbox recaptcha, token scaduto",
				en: "You need to re-accept recaptcha checkbox, token expired",
			},
			logoutError: {
				it: "Errore in fase di logout, sarai reindirizzato alla homepage",
				en: "Error on logout, you will be redirected to the homepage",
			},
			noDescription: {
				it: "Nessuna descrizione",
				en: "No description",
			},
			selectTicket: {
				it: "Devi selezionare la quota di iscrizione",
				en: "You have to select a registration fee",
			},
			deleteUser: {
				it: "Sei sicuro di voler eliminare il tuo utente, l'operazione è irreversibile",
				en: "Are you sure you want to delete your user, the operation is irreversible",
			},
		},

		navbar: {
			moon: {
				it: "la luna",
				en: "the moon",
				si: "luna",
			},
			man: {
				it: "l'uomo",
				en: "the man",
				si: "človek",
			},
			houses: {
				it: "le case",
				en: "the houses",
				si: "domačiji",
			},
			earth: {
				it: "la terra",
				en: "the land",
				si: "zemlja",
			},
			water: {
				it: "l'acqua",
				en: "the water",
				si: "voda",
			},
			harvests: {
				it: "le vendemmie",
				en: "the harvests",
				si: "trgatve",
			},
			cellar: {
				it: "la cantina",
				en: "the cellar",
				si: "klet",
			},
			wine: {
				it: "il vino",
				en: "the wine",
				si: "vino",
			},
			contactUs: {
				it: "contatti",
				en: "contact details",
				si: "kontakt",
			},
			gpoint: {
				it: "punti G ",
				en: "G spots",
				si: "G točke",
			},
			meetUs: {
				it: "incontrarci",
				en: "meeting us",
				si: "srečanja",
			},
			lighting: {
				it: "ILLUMINAZIONE",
				en: "LIGHTING",
				si: "SVELTOBA",
			},
			moonphase: {
				it: "FASE DELLA LUNA",
				en: "MOON PHASE",
				si: "LUNINE MENE",
			},
		},

		footer: {
			company: {
				it: "Azienda",
				en: "Company",
				si: "podjetje",
			},
			pages: {
				it: "Pagine",
				en: "Pages",
				si: "strani",
			},
			termOfUse: {
				it: "condizioni d'uso",
				en: "term of use",
				si: "pogoji uporabe",
			},
			privacy: {
				it: "Privacy",
				en: "Privacy",
				si: "pravilnik o zasebnosti",
			},
			credits: {
				it: "credits",
				en: "privacy",
				si: "produkcija",
			},
			login: {
				it: "Login",
				en: "Login",
				si: "prijavi se",
			},
			cookie: {
				it: "Cookie",
				en: "Cookie",
				si: "piškotki",
			},
			contactUs: {
				it: "contatti",
				en: "contact details",
				si: "kontakt",
			},
			gpoint: {
				it: "punti G ",
				en: "G spots",
				si: "G točke",
			},
			meetUs: {
				it: "incontrarci",
				en: "meeting us",
				si: "srečanja",
			},
			news: {
				it: "news",
				en: "news",
				si: "novice",
			},
		},

		vine: {
			category: {
				it: "Categoria",
				en: "Category",
				si: "kategorija",
			},
			vintage: {
				it: "Annata",
				en: "Vintage",
				si: "Letniki",
			},
			description: {
				it: "Descrizione",
				en: "Description",
				si: "Opis",
			},
			showmore: { it: "mostra tutto", en: "show more", si: "Prikaži vse" },
			showless: { it: "mostra meno", en: "show less", si: "zobraziť menej" },
		},

		titles:{
			homepage:{
				it: "Gravner Vini | Vino in anfora | Azienda Agricola | Oslavia",
				en: "Gravner Wines | Amphora | Oslavia",
				si: "Gravner Vino | Oslavia",
			},
			contact:{
				it: "Gravner Vini  Contattaci",
				en: "Gravner  Wines | Contact Detail",
				si: "Gravner Vino| Kontakt",
			},
			meetUs:{
				it: "Gravner Vini | Incontrarci",
				en: "Gravner  Wines | Meet Us",
				si: "Gravner Vino | Srečanja",
			},
		},
		description:{
			homepage:{
				it: "L'azienda agricola Gravner di Oslavia, Gorizia, Italia, produce vino in anfora: Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				en: "Gravner, Wine, Wines, Oslavia, Gorizia, Italy, amphora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				si: "Gravner, Vino, Oslavia, Italy, amfora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
			},
			contact:{
				it: "L'azienda agricola Gravner di Oslavia, Gorizia, Italia, produce vino in anfora: Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				en: "Gravner, Wine, Wines, Oslavia, Gorizia, Italy, amphora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				si: "Gravner, Vino, Oslavia, Italy, amfora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
			},
			meetUs:{
				it: "L'azienda agricola Gravner di Oslavia, Gorizia, Italia, produce vino in anfora: Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				en: "Gravner, Wine, Wines, Oslavia, Gorizia, Italy, amphora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				si: "Gravner, Vino, Oslavia, Italy, amfora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
			},
		},
		cookiebar:{
			btn_essenziali:{
				it: "Rifiuta tutti",
				en: "Rejects all ",
				si: "Zavrača vse",
			},
			btn_all:{
				it: "Accetta tutto",
				en: "All Cookies",
				si: "Spejmi vse",
			},
			btn_more:{
				it: "Preferenze cookie",
				en: "More options",
				si: "Nastavitve piškotkov",
			},
			privacy:{
				it: "Privacy",
				en: "privacy",
				si: "Zasebnosti",
			},
			cookie_necessari:{
				it: "Cookie Necessari (Obbligatori)",
				en: "Essential cookies (Required)",
				si: "Obvezni piškotki (Obvezni)",
			},
			cookie_analitici:{
				it: "Cookie analitici",
				en: "Analytical cookies",
				si: "Analitični piškotki",
			},
			cookie_profilazione:{
				it: "Cookie di profilazione",
				en: "Profiling cookie",
				si: "Piškotki za profiliranje",
			},
			link_privacy:{
				it: "Scopri di più su",
				en: "Find out more",
				si: "Izvedite več o naši politiki",
			},
			btn_confirm:{
				it: "Conferma",
				en: "Confirm",
				si: "Potrdite svojo izbiro",
			},
		},
		puntiG:{
			europa:{
				it: "Europa",
				en: "Europe",
				si: "Evropa",
			},
			italia:{
				it: "Italia",
				en: "Italy",
				si: "Italija",
			},
			resto_mondo:{
				it: "Resto del Mondo",
				en: "Other countries",
				si: "Ostale države",
			},
			canada:{
				it: "Canada",
				en: "Canada",
				si: "Kanada",
			},
			btn_torna:{
				it: "Torna",
				en: "Back",
				si: "Nazaj",
			},
			puntiPreTitle:{
				it: "Punti G:",
				en: "G spots:",
				si: "G točke:",
			},
			puntiTitle:{
				it: "dove potete trovare i nostri vini",
				en: "where to find our wine",
				si: "kraji, v katerih najdete naša vina",
			},
			search_btn:{
				it: "Cerca",
				en: "Search",
				si: "Išči",
			},
			placeholder:{
				it:"Cerca punti-g per stato, città ecc..",
				en: "Search points-g by state, city, etc.",
				si: "Iskanje g-točk po državi, mestu itd.",
			},
			no_match:{
				it:"Nessun risultato per questa ricerca.",
				en: "No results for this search.",
				si: "Za to iskanje ni rezultatov.",
			},
			website:{
				it:"Sito Web",
				en: "Website",
				si: "Spletna stran",
			}

		},
		errorpage:{
			error:{
				it: "Pagina non trovata!",
				en: "Page not found!",
				si: "Stran ne obstaja!",
			},
			error2:{
				it: "Continui pure la navigazione sul nostro sito.",
				en: "Continue and enjoy",
				si: "Svoj obisk lahko nadaljujete na naši spletni strani",
			},
			
		},
		puntiPage:{
			name:{
				it: "Gravner Vini | Punti G",
				en: "Gravner Wines | G spots",
				si: "Gravner Vino | G točke",
			},
			description:{
				it: "L'azienda agricola Gravner di Oslavia, Gorizia, Italia, produce vino in anfora: Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				en: "Gravner, Wine, Wines, Oslavia, Gorizia, Italy, amphora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
				si: "Gravner, Vino, Oslavia, Italy, amfora, Bianco Breg, Ribolla, Rosso Gravner, Rosso Breg",
			},
			
		}
	},
};

export default Globals;
