import React, { useEffect, useState, useContext } from "react";
import CookieConsent from "react-cookie-consent";
import Globals from "../parameter/globals";
import axios from "axios";
import "../scss/cookiebar.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GeneralContext from "../context/GeneralContext";

function Cookiebar(props) {
  const [error, setError] = useState(false);

  const [message, setMessage] = useState("");

  const [FlagAnalitici, setAnalitici] = useState(0);

  const [FlagProfilazione, setProfilazione] = useState(0);

  const [linkInfo, linkInfoNoteLegali] = useState();

  const [loading, setLoading] = useState(true);

  const [isActive, setIsActive] = useState(0);
  let { lang } = useContext(GeneralContext);

  const [detailCookie, setDetailCookie] = useState({
    titolo_it: "",
    titolo_en: "",
    titolo_si: "",
    descrizione_it: "",
    descrizione_en: "",
    descrizione_si: "",
    frase_cookie_it: "",
    frase_cookie_en: "",
    frase_cookie_si: "",
    title_cookie_it: "",
    title_cookie_en: "",
    title_cookie_si: "",
    linkInfoNoteLegali_it: "",
    linkInfoNoteLegali_en: "",
    linkInfoNoteLegali_si: "",
  });
  useEffect(() => {
    getPreferencesCookies();
  }, []);
  useEffect(() => {
    checkCookie();
  }, [isActive]);

  const getPreferencesCookies = async () => {
    try {
      const response = await axios.get(Globals.GETPREFERENCECOOKIE, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setError(true);
        setMessage(response?.data?.error);
      } else {
        if (parseInt(response.data.cookie[0].cookie_bar) === 1) {
          setIsActive(parseInt(response.data.cookie[0].cookie_bar));
          setDetailCookie({
            titolo_it: response.data.cookie[0].text_info_cookies_it,
            titolo_en: response.data.cookie[0].text_info_cookies_en,
            titolo_si: response.data.cookie[0].text_info_cookies_si,
            descrizione_it: response.data.cookie[0].desc_preferenze_it,
            descrizione_en: response.data.cookie[0].desc_preferenze_en,
            descrizione_si: response.data.cookie[0].desc_preferenze_si,
            frase_cookie_it: response.data.cookie[0].frase_cookie_bar_it,
            frase_cookie_en: response.data.cookie[0].frase_cookie_bar_en,
            frase_cookie_si: response.data.cookie[0].frase_cookie_bar_si,
            title_cookie_it: response.data.cookie[0].title_preferenze_it,
            title_cookie_en: response.data.cookie[0].title_preferenze_en,
            title_cookie_si: response.data.cookie[0].title_preferenze_si,
            linkInfoNoteLegali_it: response.data.cookie[0].link_info_cookies_it,
            linkInfoNoteLegali_en: response.data.cookie[0].link_info_cookies_en,
            linkInfoNoteLegali_si: response.data.cookie[0].link_info_cookies_si,
          });
          selectedCookie(
            parseInt(response?.data?.cookie[0].cookie_analitici),
            parseInt(response?.data?.cookie[0].cookie_profilazione)
          );
        }
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  const sendCookiePreferences = async (necessari, analitici, profilazione) => {
    try {
      let formdata = new FormData();

      FlagAnalitici === 1
        ? formdata.append("analitici", JSON.stringify(analitici))
        : formdata.append("analitici", JSON.stringify(0));

      FlagProfilazione === 1
        ? formdata.append("profilazione", JSON.stringify(profilazione))
        : formdata.append("profilazione", JSON.stringify(0));
      formdata.append("necessari", JSON.stringify(necessari));

      const response = await axios.post(
        Globals.SAVEPREFERENCECOOKIE,
        formdata,
        {
          withCredentials: true,
        }
      );

      if (response?.data?.error === true) {
        setError(true);
        setMessage(response?.data?.error);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setError(true);
      setMessage(err);

      setLoading(false);
    }
  };

  const selectedCookie = (analitici, profilazione) => {
    if (profilazione === 1) {
      document.getElementById("cookie_profilazione").style.display = "block";
      setProfilazione(1);
    } else {
      document.getElementById("cookie_profilazione").style.display = "none";
    }
    if (analitici === 1) {
      document.getElementById("cookie_analitici").style.display = "block";
      setAnalitici(1);
    } else {
      document.getElementById("cookie_analitici").style.display = "none";
    }
  };

  function getCookieFunction(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    var user = getCookieFunction("cookie_consent");
    if (user !== "" && isActive === 1) {
      document.getElementById("container").style.display = "none";
    } else {
      if (user !== "" && user !== null && isActive === 1) {
        document.getElementById("container").style.display = "block";
      }
    }
  }

  function acceptAllCookie() {
    var date = new Date();
    date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
    document.cookie = "cookie_consent= tecnici" + expires;
    document.getElementById("container").style.display = "none";
    sendCookiePreferences(1, 1, 1);
  }
  function acceptNecessaryCookie() {
    var date = new Date();
    date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
    document.cookie = "cookie_consent= tecnici" + expires;
    document.getElementById("container").style.display = "none";
    sendCookiePreferences(1, 0, 0);
  }
  function preferenzeCookie() {
    document.getElementById("theModal").style.display = "block";
    document.getElementById("overlay").style.display = "block";
  }
  function closeModal() {
    document.getElementById("theModal").style.display = "none";
    document.getElementById("overlay").style.display = "none";
  }
  function closeCookie() {
    if (
      window.confirm(
        "Closing the cookie bar will accept only necessary cookies. Confirm?"
      ) === true
    ) {
      document.getElementById("container").style.display = "none";
      acceptNecessaryCookie();
    } else {
      document.getElementById("container").style.display = "block";
    }
  }

  function confirmPreferences() {
    if (
      document.querySelector("#analitici").checked &&
      document.querySelector("#profilazione").checked
    ) {
      var date = new Date();
      date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
      document.cookie =
        "cookie_consent= tecnici| analitici | profilazione" + expires;
      document.getElementById("container").style.display = "none";
      document.getElementById("theModal").style.display = "none";
      document.getElementById("overlay").style.display = "none";
      sendCookiePreferences(1, 1, 1);
    } else if (document.querySelector("#analitici").checked) {
      var date = new Date();
      date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
      document.cookie = "cookie_consent= tecnici | analitici" + expires;
      document.getElementById("container").style.display = "none";
      document.getElementById("theModal").style.display = "none";
      document.getElementById("overlay").style.display = "none";
      sendCookiePreferences(1, 1, 0);
    } else if (document.querySelector("#profilazione").checked) {
      var date = new Date();
      date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
      document.cookie = "cookie_consent= tecnici| profilazione" + expires;
      document.getElementById("container").style.display = "none";
      document.getElementById("theModal").style.display = "none";
      document.getElementById("overlay").style.display = "none";
      sendCookiePreferences(1, 0, 1);
    } else {
      acceptNecessaryCookie();
    }
  }

  return (
    <div>
      {isActive === 1 ? (
        <div id="container">
          {/* overlay */}
          <div id="overlay" className="overlay"></div>
          {/* componente cookiebar */}
          <CookieConsent
            location="bottom"
            buttonStyle={{ display: "none" }}
            className="cookiebar"
            style={{
              background: "#eeeeee",
              color: "#000000",
              width: "100%",
            }}
            expires={125}
          >
            <div
              onClick={() => closeCookie()}
              className="closeModal float-end"
            ></div>
            <strong style={{ fontSize: "25px" }}>
              {lang === "it"
                ? detailCookie.titolo_it
                : lang === "en"
                ? detailCookie.titolo_en
                : detailCookie.titolo_si}
            </strong>
            <br></br>
            {/*<br></br>*/}
            <p className="py-2">
              {" "}
              {lang === "it"
                ? detailCookie.frase_cookie_it
                : lang === "en"
                ? detailCookie.frase_cookie_en
                : detailCookie.frase_cookie_si}
            </p>

            <div className="d-flex flex-direction-row div-button-cookiebar">
              <div className="col-sm-2 mx-2">
                <button
                  onClick={() => acceptNecessaryCookie()}
                  className="btn btn-primary btnAcceptAll w-100"
                >
                  <div>{Globals.language.cookiebar.btn_essenziali[lang]}</div>
                  <div></div>
                </button>
              </div>

              <div className="col-sm-2 mx-2">
                <button
                  onClick={() => acceptAllCookie()}
                  className="btn btn-success btnAcceptAll w-100"
                >
                  <div>{Globals.language.cookiebar.btn_all[lang]}</div>
                </button>
              </div>

              {/*<button*/}
              {/*  onClick={() => acceptNecessaryCookie()}*/}
              {/*  className="btn btn-primary w-25 mx-2"*/}
              {/*>*/}
              {/*  <div>Necessari</div>*/}
              {/*</button>*/}

              {/*<button onClick={() => acceptAllCookie()} className="btn btn-success ">*/}
              {/*  <div>Accetta</div>*/}
              {/*</button>*/}
            </div>
            <div className="preferences">
              <button
                className="float-end btn btn-secondary btnPreferenze"
                onClick={() => preferenzeCookie()}
              >
                {Globals.language.cookiebar.btn_more[lang]}
              </button>
              <a
                className="float-end"
                style={{
                  color: "rgb(0, 102, 204)",
                  fontSize: "18px",
                  marginRight: "10px",
                }}
                href={linkInfo}
                target="_blank"
              >
                {Globals.language.cookiebar.privacy[lang]}
              </a>
            </div>
          </CookieConsent>

          {/* finestra modale */}
          <div
            className="modal modal-cookie"
            id="theModal"
            style={{ display: "none" }}
          >
            <article className="modal-container">
              <header className="modal-container-header">
                <h1 className="modal-container-title">
                  {lang === "it"
                    ? detailCookie.title_cookie_it
                    : lang === "en"
                    ? detailCookie.title_cookie_en
                    : detailCookie.title_cookie_si}
                </h1>
                <div onClick={() => closeModal()} className="closeModal"></div>
              </header>

              <div className="modal-container-body rtf">
                <h2 className="p-2">
                  {lang === "it"
                    ? detailCookie.descrizione_it
                    : lang === "en"
                    ? detailCookie.descrizione_en
                    : detailCookie.descrizione_si}
                </h2>
                <Container className="py-3 overflow-auto">
                  <Row id="cookie_necessari">
                    <Col
                      className="py-3"
                      style={{ fontSize: "20px", fontWeight: "300" }}
                    >
                      {Globals.language.cookiebar.cookie_necessari[lang]}
                      <label className="switch float-end">
                        {/* checked */}
                        <input type="checkbox" id="necessari" />
                        <span
                          htmlFor="necessari"
                          className="slider-disable round"
                        ></span>
                      </label>
                    </Col>
                  </Row>
                  <Row id="cookie_analitici">
                    <Col
                      className="py-3"
                      style={{ fontSize: "20px", fontWeight: "300" }}
                    >
                      {Globals.language.cookiebar.cookie_analitici[lang]}
                      <label className="switch float-end">
                        <input type="checkbox" id="analitici" />
                        <span
                          htmlFor="analitici"
                          className="slider round"
                        ></span>
                      </label>
                    </Col>
                  </Row>
                  <Row id="cookie_profilazione">
                    <Col
                      className="py-3"
                      style={{ fontSize: "20px", fontWeight: "300" }}
                    >
                      {Globals.language.cookiebar.cookie_profilazione[lang]}
                      <label className="switch float-end">
                        <input type="checkbox" id="profilazione" />
                        <span
                          htmlFor="profilazione"
                          className="slider round"
                        ></span>
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        className="float-end nobottommargin link-ext mt-3"
                        style={{ fontSize: "20px", fontWeight: "300" }}
                      >
                        <i className="fa fa-fw fa-external-link"></i>
                        {Globals.language.cookiebar.link_privacy[lang]} {""}
                        <a href={linkInfo} target="_blank">
                          <span
                            style={{ fontSize: "20px", fontWeight: "300" }}
                            className="link-policy"
                          >
                            {Globals.language.cookiebar.privacy[lang]}
                          </span>
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
              <footer className="d-flex justify-content-center p-3 footer-cookiebar">
                <button
                  className="btn btn-success w-auto "
                  onClick={() => confirmPreferences()}
                >
                  {Globals.language.cookiebar.btn_confirm[lang]}
                </button>
              </footer>
            </article>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Cookiebar;
