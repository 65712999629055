import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/slideshow.scss";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Globals from "../parameter/globals";
import { Helmet } from "react-helmet";

function Luna(props) {
  const parse = require("html-react-parser");

  return (
    <div className="Luna" id="luna">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Luna - Gravner</title>
        <link rel="canonical" href="#luna" />
      </Helmet>
      <div className="mt-5">
        <img
          className="img-fluid image-card-carousel"
          src={Globals.domain + props.mainImage.path}
          alt={Globals.domain + props.mainImage.alt}
        />
      </div>

      <div style={{ backgroundColor: "#000", textAlign: "left" }}>
        <div className="mx-2" style={{ color: "white" }}>
          {props.contents.htmlcontents_it !== undefined
            ? props.lang === "it"
              ? parse(props.contents.htmlcontents_it)
              : props.lang === "en"
              ? parse(props.contents.htmlcontents_en)
              : parse(props.contents.htmlcontents_si)
            : ""}
        </div>

        <CardGroup>
          {props.images?.map((item) => {
            return (
              <Card className="border-0">
                <Card.Img
                  variant="top"
                  src={Globals.domain + item.path_immagine}
                  alt="Fase della luna nei diversi periodi"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    {props.lang === "it"
                      ? item.titolo_it
                      : props.lang === "en"
                      ? item.titolo_en
                      : item.titolo_si}
                  </Card.Title>
                  <Card.Text className="text-center">
                    {props.lang === "it"
                      ? parse(item.testo_it)
                      : props.lang === "en"
                      ? parse(item.testo_en)
                      : parse(item.testo_si)}
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          })}
        </CardGroup>
      </div>
    </div>
  );
  // }
}

export default Luna;
