import axios from "axios";
import React, {useState, useEffect, useContext} from "react";
import Globals from "../parameter/globals";
import Loading from "../components/Loading";
import mondo from "../images/mondo.jpg";
import europa from "../images/europa.jpg";
import italia from "../images/italia.jpg";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../scss/punti.scss";
import {BsChevronLeft} from "react-icons/bs";
import {Card} from "react-bootstrap";
import GeneralContext from "../context/GeneralContext";
import {Helmet} from "react-helmet";

const steps = [
	"Punti G - Continente",
	"Punti G - Stato",
	"Punti G - Regione",
	"Punti G - Canada",
];
function Punti() {
	const [loading, setLoading] = useState(false);
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const [data, setData] = useState([]);
	const [continente, setContinente] = useState("");
	const [stato, setStato] = useState("");
	const [regione, setRegione] = useState("");
	const [keySearch, setKeySearch] = useState("");
	const {lang} = useContext(GeneralContext);
	const [search, seeSearch] = useState(0);

	useEffect(() => {
		getPunti();
	}, [continente, stato, regione]);

	const getPunti = async (cerca) => {
		setLoading(true);
		if (cerca) {
			seeSearch(1);
		}
		let body = {
			continente: continente,
			stato: stato,
			regione: regione,
			keySearch: keySearch,
			lang: lang,
		};

		let formdata = new FormData();

		formdata.append("body", JSON.stringify(body));
		try {
			const response = await axios.post(
				Globals.GETPUNTIG + "&lang=" + lang,
				formdata,
				{
					withCredentials: true,
				},
			);
			if (response?.data?.error === true) {
				// setError(true);
				// setMessageError(response.data.message);
				console.log("errore");
				setLoading(false);
				// setSuccess(false);
			} else {
				//clear state and controlled inputs
				// setError(true);
				// setSuccess(true);
				setData(response.data.punti_g || []);

				setLoading(false);
			}
		} catch (err) {
			// setLoading(false);
			// setSuccess(false);
			console.log(err);
			// setMessageError(Globals.language.errorLabel.serverError[lang]);
		}
	};

	const redirectToExternalSite = (site) => {
		if (site.includes("https://") || site.includes("http://")) {
			window.open(site, "_blank ");
		} else {
			window.open("https://" + site, "_blank ");
		}
	};

	const backContinents = () => {
		setKeySearch("");
		getPunti();
		window.location.reload();
	};
	const reset = () => {
		setKeySearch("");
	};

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = (element, elemnt2, element3) => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
		setContinente(element);
		setStato(elemnt2);
		setRegione(element3);
	};

	const handleBack = () => {
		if (continente !== "" && stato !== "" && regione !== "") {
			setRegione("");
		} else if (continente !== "" && stato !== "") {
			if (stato === "Italia" || stato === "Italy" || stato === "Italija") {
				setContinente("");
				setStato("");
			} else {
				setStato("");
			}
		} else {
			setContinente("");
		}

		setTimeout(() => {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}, 250);

		// getPunti();
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.puntiPage.name[lang]}</title>
				<meta
					name='description'
					content={Globals.language.puntiPage.description[lang]}
				/>
				<link rel='canonical' href='https://www.gravner.it/' />
			</Helmet>
			{loading ? (
				<Loading />
			) : (
				<div className='container-fluid '>
					<Box sx={{width: "100%"}}>
						<Stepper nonLinear activeStep={activeStep}>
							{steps.map((label, index) => (
								<Step key={label} completed={completed[index]}>
									{/* <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton> */}
								</Step>
							))}
						</Stepper>
						<div className='text-center responsive-input'>
							<div className='col-xs-12 col-md-12'>
								<h6>
									<strong>{Globals.language.puntiG.puntiPreTitle[lang]}</strong>{" "}
									{Globals.language.puntiG.puntiTitle[lang]}
								</h6>
							</div>
							<div className='col-xs-12 col-md-12'>
								<div className='form-group form-input-fields form-group-lg has-feedback w-25 float-end responsive-search'>
									<label className='sr-only' for='search'>
										{Globals.language.puntiG.search_btn[lang]}
									</label>
									<div className='input-group'>
										<input
											type='text'
											className='form-control input-search'
											name='q'
											id='search'
											placeholder={Globals.language.puntiG.placeholder[lang]}
											value={keySearch}
											onChange={(e) => setKeySearch(e.target.value)}
										/>
										<span className='input-group-addon group-icon'>
											{" "}
											<span className='glyphicon glyphicon-user'></span>
										</span>
										{/* {keySearch !== "" ? (
                    <button
                      type="submit"
                      className="btn btn-secondary btn-reset"
                      disabled={keySearch === ""}
                      onClick={() => backContinents()}
                    >
                      <span
                        className="glyphicon glyphicon-search"
                        aria-hidden="true"
                      ></span>{" "}
                      Reset
                    </button>
                    ):(<></>)} */}
										<button
											type='submit'
											className='btn btn-lg btn-search'
											disabled={keySearch === ""}
											onClick={() => getPunti("cerca")}>
											<span
												className='glyphicon glyphicon-search'
												aria-hidden='true'></span>{" "}
											{Globals.language.puntiG.search_btn[lang]}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div>
							{search === 1 ? (
								<div>
									<Button
										color='inherit'
										className='btn-responsive-puntig'
										onClick={() => backContinents()}
										sx={{mr: 1}}>
										<BsChevronLeft />
										{Globals.language.puntiG.btn_torna[lang]}
									</Button>
									<div
										className='principal-div-search sticky '
										style={{
											backgroundImage: `url(${
												continente === Globals.language.puntiG.europa[lang]
													? europa
													: stato === Globals.language.puntiG.italia[lang]
													? italia
													: mondo
											})`,
											backgroundRepeat: "no-repeat",
											width: "100%",
											height: "700px",
											backgroundSize: "contain",
										}}>
										{/* <Button
                          color="inherit"
                          className="btn-responsive-puntig"
                          disabled={activeStep === 0}
                          onClick={() => setk}
                          sx={{ mr: 1 }}
                        >
                          <BsChevronLeft/>Torna
                        </Button> */}

										<div>
											{data?.punti_vendita.length === 0 && (
												<Card className='w-100'>
													<span>{Globals.language.puntiG.no_match[lang]}</span>
												</Card>
											)}
											<div className='contents'>
												{data?.punti_vendita?.map((item, index) => {
													return (
														<div key={index}>
															<span
																style={{
																	color: "#988176",
																	fontWeight: "600",
																}}>
																{item?.insegna}
															</span>
															<br></br>
															{item?.stato_mondo_it ===
															Globals.language.puntiG.italia[lang] ? (
																<></>
															) : (
																<div>
																	{item.continente_mondo_it} <br></br>
																</div>
															)}

															{item?.stato_mondo_it !== "" ? (
																<div>
																	{item.stato_mondo_it}
																	<br></br>
																</div>
															) : (
																<></>
															)}

															{item?.indirizzo !== "" ? (
																<div>
																	{item.indirizzo}
																	<br></br>
																</div>
															) : (
																<></>
															)}
															{item?.telefono != '' ? (
																<div>
																	Tel: <a href={"Tel:" + item.telefono}>{item.telefono}</a>{" "}
																	<br></br>
																</div>
															) : (
																<></>
															)}
															{item?.citta_mondo !== "" ? (
																<div>
																	{" "}
																	{item?.citta_mondo} ({item?.provincia_mondo_it})<br></br>
																</div>
															) : (
																<></>
															)}
															{/* {item?.fax !== "" ? (
																<div>
																	{item.fax}
																	<br></br>
																</div>
															) : (
																<></>
															)} */}

															{item?.email !== undefined && parseInt(item?.email_availability) === 1? (
																<div >
																	{" "}
																	{/* Adds padding around the div */}
																	<a href={`mailto:${item.email}`} className='btn btn-link'>
																		{Globals.language.contacts.emailForm[lang]}
																	</a>{" "}
																	{/* Styles the link as a Bootstrap button */}
																</div>
															) : (
																<></>
															)}
															{item?.sito_internet !== undefined && parseInt(item?.web_availability) === 1 ? (
																			// <button
																			//   className="btn"
																			//   onClick={() =>
																			//     (window.location.href =
																			//       item?.sito_internet)
																			//   }
																			// >
																			//   Website
																			// </button>
																			<a
																				className='btn btn-link'
																				onClick={() => redirectToExternalSite(item?.sito_internet)}
																				target='_blank'>
																				{" "}
																				{Globals.language.puntiG.website[lang]}
																			</a>
																		) : (
																			""
																		)}
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							) : (
								<React.Fragment>
									<Typography sx={{mt: 2, mb: 1, py: 1}}>
										{activeStep === 0 ? (
											<div
												className='principal-div sticky'
												style={{
													backgroundImage: `url(${
														continente === Globals.language.puntiG.europa[lang]
															? europa
															: stato === Globals.language.puntiG.italia[lang]
															? italia
															: mondo
													})`,
													backgroundRepeat: "no-repeat",
													width: "100%",
													height: "700px",
													backgroundSize: "contain",
												}}>
												<div className='contents_seconds'>
													<button
														className='btn  button-punti '
														onClick={() =>
															handleNext(
																Globals.language.puntiG.europa[lang],
																Globals.language.puntiG.italia[lang],
																"",
															)
														}>
														{Globals.language.puntiG.italia[lang]}
													</button>
													<button
														className='btn button-punti'
														onClick={() =>
															handleNext(Globals.language.puntiG.europa[lang], "", "")
														}>
														{Globals.language.puntiG.europa[lang]}
													</button>
													<button
														className='btn button-punti'
														onClick={() =>
															handleNext(Globals.language.puntiG.resto_mondo[lang], "", "")
														}>
														{Globals.language.puntiG.resto_mondo[lang]}
													</button>
												</div>
											</div>
										) : (
											<></>
										)}
										{activeStep === 1 ? (
											<div>
												<Button
													color='inherit'
													className='btn-responsive-puntig'
													disabled={activeStep === 0}
													onClick={() => handleBack()}
													sx={{mr: 1}}>
													<BsChevronLeft />
													{Globals.language.puntiG.btn_torna[lang]}
												</Button>
												<div
													className='principal-div'
													style={{
														backgroundImage: `url(${
															continente === Globals.language.puntiG.europa[lang] &&
															stato !== Globals.language.puntiG.italia[lang]
																? europa
																: stato === Globals.language.puntiG.italia[lang]
																? italia
																: mondo
														})`,
														backgroundRepeat: "no-repeat",
														width: "100%",
														height: "700px",
														backgroundSize: "contain",
													}}>
													<div className='contents'>
														{stato === Globals.language.puntiG.italia[lang] ? (
															<>
																{data?.punti_vendita?.map((item, index) => {
																	return (
																		<div key={index}>
																			<button
																				className='btn button-punti'
																				onClick={() => handleNext(continente, stato, item)}>
																				{item}
																			</button>
																		</div>
																	);
																})}
															</>
														) : (
															<>
																{data?.punti_vendita?.map((item, index) => {
																	return (
																		<div key={index}>
																			<button
																				className='btn button-punti'
																				onClick={() => handleNext(continente, item, "")}>
																				{item}
																			</button>
																		</div>
																	);
																})}
															</>
														)}
													</div>
												</div>
											</div>
										) : (
											<></>
										)}
										{activeStep === 2 ? (
											<div>
												<Button
													color='inherit'
													className='btn-responsive-puntig'
													disabled={activeStep === 0}
													onClick={() => handleBack()}
													sx={{mr: 1}}>
													<BsChevronLeft /> {Globals.language.puntiG.btn_torna[lang]}
												</Button>
												<div
													className='principal-div sticky'
													style={{
														backgroundImage: `url(${
															continente === Globals.language.puntiG.europa[lang] &&
															stato !== Globals.language.puntiG.italia[lang]
																? europa
																: stato === Globals.language.puntiG.italia[lang]
																? italia
																: mondo
														})`,
														backgroundRepeat: "no-repeat",
														width: "100%",
														height: "700px",
														backgroundSize: "contain",
													}}>
													{stato === Globals.language.puntiG.canada[lang] ? (
														<div className='contents'>
															{data?.punti_vendita?.map((item, index) => {
																return (
																	<div key={index}>
																		<button
																			className='btn button-punti'
																			onClick={() => handleNext(continente, stato, item)}>
																			{item}
																		</button>
																	</div>
																);
															})}
														</div>
													) : (
														<div className='contents'>
															{data?.punti_vendita?.map((item, index) => {
																return (
																	<div key={index}>
																		{/* <Card >
      <Card.Header >
        <Nav variant="pills">
          <p style={{color:"#988176", fontWeight:"600"}} className="text-center">{item?.insegna}</p>
         
        </Nav>
      </Card.Header>
      <Card.Body>
        <Card.Text>
        <p>{item?.indirizzo}</p>
        <p>{item?.cap}</p>
        <p>{item?.citta_mondo} ({item?.provincia_mondo})</p>
        <p>{item?.telefono !== "" ? "Tel.: " + item?.telefono : "" }</p>
        <p>{item?.fax !== "" ?  "Fax: "+item?.fax : "" }</p>

        <p>{item?.email}</p>
        <p>{item?.sito_internet !== "" ?  item?.sito_internet : "" }</p>

        </Card.Text>
      </Card.Body>
    </Card> */}
																		<span
																			style={{
																				color: "#988176",
																				fontWeight: "600",
																			}}>
																			{item?.insegna}
																		</span>
																		<br></br>
																		{item?.indirizzo !== undefined ? (
																			<div>
																				{item.indirizzo}
																				<br></br>
																			</div>
																		) : (
																			<></>
																		)}
																		{item?.cap !== undefined ? (
																			<div>
																				{item.cap}
																				<br></br>
																			</div>
																		) : (
																			<></>
																		)}
																		{item?.citta_mondo !== undefined ? (
																			<div>
																				{" "}
																				{item?.citta_mondo} ({item?.provincia_mondo})<br></br>
																			</div>
																		) : (
																			<></>
																		)}
																		{item?.telefono !== ''  ? (
																			<div>
																				Tel: <a href={"Tel:" + item.telefono}>{item.telefono}</a>{" "}
																				<br></br>
																			</div>
																		) : (
																			<></>
																		)}
																		{/* {item?.fax !== undefined ? (
																			<div>
																				Fax: {item.fax}
																				<br></br>
																			</div>
																		) : (
																			<></>
																		)} */}

																		{item?.email !== undefined && parseInt(item?.email_availability) === 1 ? (
																			<div >
																				{" "}
																				<a
																					href={`mailto:${item.email}`}
																					className='btn btn-link'>
																					{Globals.language.contacts.emailForm[lang]}
																				</a>{" "}
																			</div>
																		) : (
																			<></>
																		)}

																		{item?.sito_internet !== undefined && parseInt(item?.web_availability) === 1 ? (
																			// <button
																			//   className="btn"
																			//   onClick={() =>
																			//     (window.location.href =
																			//       item?.sito_internet)
																			//   }
																			// >
																			//   Website
																			// </button>
																			<a
																				className='btn btn-link'
																				onClick={() => redirectToExternalSite(item?.sito_internet)}
																				target='_blank'>
																				{" "}
																				{Globals.language.puntiG.website[lang]}
																			</a>
																		) : (
																			""
																		)}
																	</div>
																);
															})}
														</div>
													)}
												</div>
											</div>
										) : (
											<></>
										)}
										{activeStep === 3 ? (
											<div>
												<Button
													className='btn-responsive-puntig'
													color='inherit'
													disabled={activeStep === 0}
													onClick={() => handleBack()}
													sx={{mr: 1}}>
													<BsChevronLeft /> {Globals.language.puntiG.btn_torna[lang]}
												</Button>
												<div
													className='principal-div sticky'
													style={{
														backgroundImage: `url(${mondo})`,
														backgroundRepeat: "no-repeat",
														width: "100%",
														height: "700px",
														backgroundSize: "contain",
													}}>
													<div className='contents'>
														{data?.punti_vendita?.map((item, index) => {
															return (
																<div key={index}>
																	<span
																		style={{
																			color: "#988176",
																			fontWeight: "600",
																		}}>
																		{item?.insegna}
																	</span>

																	{item?.indirizzo !== undefined ? (
																		<div>
																			{item.indirizzo}
																			<br></br>
																		</div>
																	) : (
																		<></>
																	)}
																	{item?.cap !== undefined ? (
																		<div>
																			{item.cap}
																			<br></br>
																		</div>
																	) : (
																		<></>
																	)}
																	{item?.citta_mondo !== undefined ? (
																		<div>
																			{" "}
																			{item?.citta_mondo} ({item?.provincia_mondo})<br></br>
																		</div>
																	) : (
																		<></>
																	)}
																	{item?.telefono != '' ? (
																		<div>
																			Tel: <a href={"Tel:" + item.telefono}>{item.telefono}</a>{" "}
																			<br></br>
																		</div>
																	) : (
																		<></>
																	)}
																	{/* {item?.fax !== undefined ? (
																		<div>
																			Fax: {item.fax}
																			<br></br>
																		</div>
																	) : (
																		<></>
																	)} */}

																	{item?.email !== undefined && parseInt(item?.email_availability) === 1? (
																		<div >
																			{" "}
																			{/* Adds padding around the div */}
																			<a href={`mailto:${item.email}`} className='btn btn-link'>
																				{Globals.language.contacts.emailForm[lang]}
																			</a>{" "}
																			{/* Styles the link as a Bootstrap button */}
																		</div>
																	) : (
																		<></>
																	)}
																	{item?.sito_internet !== undefined && parseInt(item?.web_availability) === 1 ? (
																			// <button
																			//   className="btn"
																			//   onClick={() =>
																			//     (window.location.href =
																			//       item?.sito_internet)
																			//   }
																			// >
																			//   Website
																			// </button>
																			<a
																				className='btn btn-link'
																				onClick={() => redirectToExternalSite(item?.sito_internet)}
																				target='_blank'>
																				{" "}
																				{Globals.language.puntiG.website[lang]}
																			</a>
																		) : (
																			""
																		)}
																</div>
															);
														})}
													</div>
												</div>
											</div>
										) : (
											<></>
										)}
									</Typography>
									{/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleNext} sx={{ mr: 1 }}>
                      Next
                    </Button>
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <Button onClick={handleComplete}>
                          {completedSteps() === totalSteps() - 1
                            ? "Finish"
                            : "Complete Step"}
                        </Button>
                      ))}
                  </Box> */}
								</React.Fragment>
							)}
						</div>
					</Box>
				</div>
			)}
		</>
	);
}

export default Punti;
