import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/uomo.scss";
import Carousel from "react-bootstrap/Carousel";
import acqua from "../images/acqua/acqua.jpg";
import Globals from "../parameter/globals";
import { Helmet } from "react-helmet";

function Acqua(props) {
	const parse = require("html-react-parser");

	return (
		<div className='App' id='acqua'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Acqua - Gravner</title>
				<link rel='canonical' href='#acqua' />
			</Helmet>
			<Carousel pause={false} className='py-5'>
				{props.images?.map((item) => {
					return (
						<Carousel.Item interval={2000}>
							<img
								className='d-block w-100'
								src={Globals.domain + item.path_immagine}
								alt='Water'
							/>
						</Carousel.Item>
					);
				})}
			</Carousel>
			<div>
				{props.contents.htmlcontents_it !== undefined
					? props.lang === "it"
						? parse(props.contents.htmlcontents_it)
						: props.lang === "en"
						? parse(props.contents.htmlcontents_en)
						: parse(props.contents.htmlcontents_si)
					: ""}
			</div>
			<div className='d-flex justify-content-center w-100 text-center mx-auto py-2'>
				<img className='img-fix' src={acqua} alt="Acqua"></img>
			</div>
		</div>
	);
	// }
}

export default Acqua;
