import { Card } from "react-bootstrap";
import GeneralContext from "../context/GeneralContext";
import React, { useState, useEffect, useContext } from "react";
import Globals from "../parameter/globals";

function ErrorPage() {
  const { lang } = useContext(GeneralContext);

  return (
    <div className="p-2 incontraci-resp" style={{ minHeight: "520px" }}>
      {" "}
      <Card className=" principal-div sticky" style={{ fontSize: "25px" }}>
        <i
          className="bi bi-check-circle"
          style={{ fontSize: "50px", color: "green" }}
        ></i>
        {Globals.language.errorpage.error[lang]}
        <div>{Globals.language.errorpage.error2[lang]}</div>
        <a href="/">www.gravner.it</a>
      </Card>
    </div>
  );
}

export default ErrorPage;
