import axios from "axios";
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Globals from "../parameter/globals";
import Form from "react-bootstrap/Form";
import Loading from "../components/Loading";
import { Card, Button } from "react-bootstrap";
import GeneralContext from "../context/GeneralContext";
import { Helmet } from "react-helmet";
import "../scss/contatti.scss";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";

function Contact() {
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [privacy, setPrivacy] = useState("");
	const [token, setToken] = useState("");

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const [error, setError] = useState(false);
	const [messageError, setMessageError] = useState("");

	const { lang } = useContext(GeneralContext);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (token === "") {
			setMessageError(Globals.language.errorLabel.acceptRecaptcha[lang]);
			return false;
		}

		try {
			setLoading(true);
			let body = {
				name: name,
				surname: surname,
				email: email,
				phone: phone,
				message: message,
				privacy: privacy,
				token: token,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GENERALCONTACT + "&lang=" + lang,
				formdata,
				{
					withCredentials: true,
				}
			);
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setError(true);
				setMessageError(response.data.message);

				setLoading(false);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setError(true);
				setLoading(false);
				setSuccess(true);
			}
		} catch (err) {
			setLoading(false);
			setSuccess(false);
			console.log(err);
			setMessageError(Globals.language.errorLabel.serverError[lang]);
		}
	};

	const v2Callback = (token) => {
		if (typeof token === "string") {
			setToken(token);
		} else if (typeof token === "boolean" && !token) {
			setMessageError(Globals.language.errorLabel.recaptchaExpired[lang]);
			setToken("");
		} else if (token instanceof Error) {
			setMessageError(Globals.language.errorLabel.serverError[lang]);
			setToken("");
		}
	};

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.titles.contact[lang]}</title>
				<meta
					name='description'
					content={Globals.language.description.contact[lang]}
				/>
				<link rel='canonical' href='https://www.gravner.it/' />
			</Helmet>
			{loading ? (
				<Loading />
			) : success ? (
				<div className='p-2  text-center d-flex justify-content-center mx-auto align-items-center' style={{minHeight:"520px"}}>
					{" "}
					<Card style={{ fontSize: "25px" }}>
						<i
							className='bi bi-check-circle'
							style={{ fontSize: "50px", color: "green" }}></i>
						{Globals.language.contacts.sendSuccess[lang]}
						<div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
					</Card>
				</div>
			) : (
				<Form
					className='container incontraci-resp'
					onSubmit={(e) => {
						handleSubmit(e);
					}}>
					<h2 className='text-center my-2'>
						{Globals.language.contacts.contactInvitation[lang]}
					</h2>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
						<Form.Control
							type='name'
							placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
						<Form.Control
							type='surname'
							placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
							value={surname}
							onChange={(e) => setSurname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
						<Form.Control
							type='email'
							placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
						<Form.Control
							type='phone'
							placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>{Globals.language.contacts.messaggeForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.messaggeFormPlaceholder[lang]}
							as='textarea'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className='mb-3 d-flex'  controlId='formBasicCheckbox'>
					<Form.Check
							type='checkbox'
							onChange={(e) =>
								e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
							}
							required
							id="formBasicCheckbox"
							
						/>
					<Form.Label htmlFor="formBasicCheckbox" className="mx-2">{Globals.language.contacts.privacyForm[lang]} <a  href={"/contact-term-of-use"}> {Globals.language.contacts.privacyLabel[lang]}  </a></Form.Label>
					

					</Form.Group>

					<Form.Group className='position-relative' controlId='formBasicCheckbox'>
						<ReCaptchaV2
							callback={v2Callback}
							theme={EReCaptchaV2Theme.Light}
							size={EReCaptchaV2Size.Normal}
							id='my-id'
							data-test-id='my-test-id'
							tabindex={0}
						/>
					</Form.Group>

					<Button
						variant='danger'
						className='d-flex justify-content-center mx-auto my-2 w-25'
						type='submit'>
						<span style={{ fontSize: "25px" }}>
							{Globals.language.contacts.sendButtonForm[lang]}
						</span>
					</Button>
					{messageError && <p> {messageError} </p>}
				</Form>
			)}
		</>
	);
}

export default Contact;
