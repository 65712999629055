import { createContext, useState } from "react";

const GeneralContext = createContext({});

export const GeneralProvider = ({ children }) => {
	let languageFromStorage =
		localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : "it";

	const [lang, setLang] = useState(languageFromStorage);

	return (
		<GeneralContext.Provider value={{ lang, setLang }}>
			{children}
		</GeneralContext.Provider>
	);
};

export default GeneralContext;
