import React, { useEffect, useContext, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Globals from "../parameter/globals";
import axios from "axios";
import GeneralContext from "../context/GeneralContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/newvini.scss";
import Card from "react-bootstrap/Card";

function Vini() {
	//in base all'url vado a pescare le proprietà del vino
	let { id, slug } = useParams();
	let { lang } = useContext(GeneralContext);
	const parse = require("html-react-parser");

	const [detailVino, setDetailVino] = useState({
		nome_vino_it: "",
		nome_vino_en: "",
		nome_vino_si: "",
		img: "",
		annata: null,
		testo_vino_it: "",
		testo_vino_en: "",
		testo_vino_si: "",
		nome_categoria_it: "",
		nome_categoria_en: "",
		nome_categoria_si: "",
		id_categoria_vino: null,
	});
	const [attachamentsVino, setAttachmentsVino] = useState([]);

	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");

	const getVino = async () => {
		setError(false);
		setMessage("");

		let body = {
			id: id,
		};

		let formdata = new FormData();

		formdata.append("body", JSON.stringify(body));

		const response = await axios.post(
			Globals.GETVINO + "&lang=" + lang,
			formdata,
			{
				withCredentials: true,
			}
		);

		setError(response?.data.error);
		setMessage(response?.data.message);

		let vino = response?.data?.vino;
		setDetailVino({
			nome_vino_it: vino.nome_vino_it,
			nome_vino_en: vino.nome_vino_en,
			nome_vino_si: vino.nome_vino_si,
			img: Globals.domain + "images_vini/original/" + id + ".jpg",
			testo_vino_it: vino.testo_vino_it,
			testo_vino_en: vino.testo_vino_en,
			testo_vino_si: vino.testo_vino_si,
			nome_categoria_it: vino.nome_categoria_it,
			nome_categoria_en: vino.nome_categoria_en,
			nome_categoria_si: vino.nome_categoria_si,
			id_categoria_vino: vino.categorie_vini_id,
			annata: vino.anno,
		});
		setAttachmentsVino(response.data.allegati || []);
	};

	useEffect(() => {
		getVino();
	}, []);

	return (
		<>
			{error === true ? (
				<section style={{ textAlign: "center" }}>
					{" "}
					<h1> {message}</h1>
				</section>
			) : (
				<section className='d-flex justify-content-center container-fluid my-5'>
					<Card
						style={{ width: "100%", backgroundColor: "#fff",marginTop:"100px" }}
						className='mb-0 mx-auto card-resp'>
						<Card.Title className='text-dark py-2 fs-1 text-center'>
							<strong>
								{" "}
								{lang === "it"
									? detailVino.nome_vino_it
									: lang === "en"
									? detailVino.nome_vino_en
									: detailVino.nome_vino_si}
							</strong>
						</Card.Title>

						<Card.Body>
							<div className='d-flex responsive-wine-div-image'>
								<div className='col-lg-4 col-md-4 col-sm-12'>
									{/* <Zoom> */}
									<Card.Img
										className='image-wine'
										style={{
											width: "100%",
											height: "350px",
											objectFit: "contain",
										}}
										variant='top'
										src={
											detailVino.img !== undefined
												? detailVino.img
												: Globals.domain +
												  "images_categorie_vini/original/" +
												  detailVino.id_categoria_vino +
												  ".jpg"
										}
									/>

									{/* </Zoom> */}
									{/* position:"absolute",
                          left:"-160px",
                          bottom:"0" */}
								</div>
								<div className='col-lg-8 col-md-8 col-sm-12 text-dark my-auto align-content-center'>
									<strong>{Globals.language.vine.category[lang]} :</strong>{" "}
									{lang === "it"
										? detailVino.nome_categoria_it
										: lang === "en"
										? detailVino.nome_categoria_en
										: detailVino.nome_categoria_si}
									<span className='mx-2'>|</span>
									<strong>{Globals.language.vine.vintage[lang]} :</strong>{" "}
									{detailVino.annata}
									<br></br>
									{/* <strong>Bianco Breg 2012</strong> */}
									<br></br>
									<Card.Text className='text-dark mx-2'>
										{parse(
											lang === "it"
												? detailVino.testo_vino_it
												: lang === "en"
												? detailVino.testo_vino_en
												: detailVino.testo_vino_si
										)}

										<hr></hr>
										{attachamentsVino.map((item) => {
											return (
												<div className="text-center">
													<a
														href={
															Globals.domain +
															"attachments_vini/" +
															item.id +
															"_" +
															item.attachment_name
														}
														target='_blank'
														className='btn btn-warning text-light w-50'
														rel='noreferrer'
														style={{ fontWeight: "600" }}>
														{item.title}
													</a>{" "}
												</div>
											);
										})}
									</Card.Text>
								</div>
							</div>
						</Card.Body>
					</Card>
				</section>
			)}
		</>
	);
}

export default Vini;
