import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../parameter/globals";
import { Helmet } from "react-helmet";
import GeneralContext from "../context/GeneralContext";
function TermOfUse() {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [TermOfUse, setTermOfUse] = useState([]);
	const { lang } = useContext(GeneralContext);

	useEffect(() => {
		getTermOfUse();
	}, [lang]);

	const getTermOfUse = async () => {

        setError(false);
        setMessage("");


		try {
			const response = await axios.get(Globals.GETERMOFUSE, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setTermOfUse(
					lang === "it"
						? response?.data?.term.htmlcontents_it
						: lang === "en"
						? response?.data?.term.htmlcontents_en
						: response?.data?.term.htmlcontents_si
				);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};

	return (
		<>
			{error ? (
				<section className='text-center'>
					{" "}
					<h2> {message}</h2>
				</section>
			) : (
				<div
					className='container'
					style={{ marginTop: "140px", marginBottom: "100px" }}>
					<Helmet>
						<meta charSet='utf-8' />
						{/* <title>{Globals.language.title.TermOfUse[lang]}</title> */}
						<link rel='canonical' href='https://www.gravner.it/privacy' />
						<meta name="robots" content="noindex" />

					</Helmet>
					<div dangerouslySetInnerHTML={{ __html: TermOfUse }} />
				</div>
			)}
		</>
	);
}

export default TermOfUse;