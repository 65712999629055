import React from "react";

import Slideshow from "../components/Slideshow";
import Luna from "../components/Luna";
import Uomo from "../components/Uomo";
import Case from "../components/Case";
import Terra from "../components/Terra";
import Acqua from "../components/Acqua";
import Vendemmia from "../components/Vendemmie";
import Cantina from "../components/Cantina";
import NewVini from "../components/NewVini";
import axios from "axios";
import Globals from "../parameter/globals";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import GeneralContext from "../context/GeneralContext";
import { Helmet } from "react-helmet";
import Loading from "../components/Loading";


function Homepage() {
	const location = useLocation();
	const { lang } = useContext(GeneralContext);

	const [slideshow, setSlideshow] = useState([]);
	const [lunaImages, setLunaImages] = useState([]);
	const [lunaContents, setLunaContents] = useState({});
	const [mainLunaContents, setMainLunaContents] = useState({});
	const [uomoImages, setUomoImages] = useState([]);
	const [uomoContents, setUomoContents] = useState({});
	const [casaImages, setCasaImages] = useState([]);
	const [casaContents, setCasaContents] = useState([]);
	const [terraImages, setTerraImages] = useState([]);
	const [terraContents, setTerraContents] = useState({});
	const [acquaImages, setAcquaImages] = useState([]);
	const [acquaContents, setAcquaContents] = useState({});
	const [cantinaImages, setCantinaImages] = useState([]);
	const [cantinaContents, setCantinaContents] = useState({});
	const [vendemmieImages, setVendemmieImages] = useState([]);
	const [vini, setVini] = useState([]);
	const [viniTot, setViniTot] = useState([]);
	const [loading, setLoading] = useState(false);


	const getHomeContents = async () => {
		setLoading(true);
		try {
			const response = await axios.get(Globals.GETHOMEDATA + "&lang=" + lang, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});
			setLoading(false);

			let data = response?.data;
			setSlideshow(data.slideshow || []);
			setLunaImages(data.luna || []);
			setMainLunaContents(data.main_luna || {});
			setLunaContents(data.luna_contents || {});
			setUomoImages(data.uomo || []);
			setUomoContents(data.uomo_contents || []);
			setCasaImages(data.casa || []);
			setCasaContents(data.casa_contents || []);
			setTerraImages(data.terra || []);
			setTerraContents(data.terra_contents || []);
			setAcquaImages(data.acqua || []);
			setAcquaContents(data.acqua_contents || []);
			setCantinaImages(data.cantina || []);
			setCantinaContents(data.cantina_contents || {});
			setVendemmieImages(data.vendemmie || []);
			setVini(data.lista_categorie_vini || []);
			setViniTot(data.vini || []);
			scrollToIdElement();
		} catch (err) {
			console.log(err);

		}
	};

	useEffect(() => {
		getHomeContents();
	}, [lang]);

	useEffect(() => {
		scrollToIdElement();
	}, [location.hash]);

	const scrollToIdElement = async () => {
		const { hash } = window.location;
		if (hash !== "") {
			setTimeout(() => {
				const id = hash.replace("#", "");
				const yOffset = -163;
				const element = document.getElementById(id);
				const y =
					element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: "smooth" });
				
			}, 150);
		}


	};

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.titles.homepage[lang]}</title>
				<meta
					name='description'
					content={Globals.language.description.homepage[lang]}
				/>
				<link rel='canonical' href='https://www.gravner.it/' />
			</Helmet>

			{loading ? (
        <Loading />
      ) : (
		<>

			<Slideshow images={slideshow} lang={lang} />
			<Luna
				images={lunaImages}
				contents={lunaContents}
				mainImage={mainLunaContents}
				lang={lang}
			/>
			<Uomo images={uomoImages} contents={uomoContents} lang={lang} />
			<Case images={casaImages} contents={casaContents} lang={lang} />
			<Terra images={terraImages} contents={terraContents} lang={lang} />
			<Acqua images={acquaImages} contents={acquaContents} lang={lang} />
			<Vendemmia images={vendemmieImages} lang={lang} />
			<Cantina images={cantinaImages} contents={cantinaContents} lang={lang} />
			<NewVini vini={viniTot} viniTot={vini} lang={lang} />
			{/* <Vini/> */}
			</>
	  )}
		</>
	);
}

export default Homepage;
