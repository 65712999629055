import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useNavigate } from "react-router-dom";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/navbar.scss";
import logo from "../images/logo.png";
// import AnchorLink from "react-anchor-link-smooth-scroll-v2";
import axios from "axios";
import Globals from "../parameter/globals";
import luna1 from "../images/lune/0.png";
import luna2 from "../images/lune/1.png";
import GeneralContext from "../context/GeneralContext";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

function Navbar() {
  let location = useLocation();
  const [click, setClick] = React.useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  const Close = () => setClick(false);
  const [stickyClass, setStickyClass] = useState("");
  const [mesi, listaMesi] = useState([]);
  const [immagini, listaImmagini] = useState([]);
  const [fasi, listaFasi] = useState([]);
  const [percentuale, setPercentuale] = useState([]);
  const [giorniSettimana, setGiorniSettimana] = useState([]);
  const [dateDay, setDateDay] = useState([]);
  const [percIlluminazione, setPercIlluminazione] = useState([]);
  const [nomeFase, setNomeFase] = useState([]);
  const { lang, setLang } = useContext(GeneralContext);
  const [isActive, setActive] = useState(lang);
  const navigate = useNavigate();

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = (item) => {
    setIsHovering(true);
    setGiorniSettimana(item.week_day);
    setDateDay(item.date_day);
    setPercIlluminazione(item.perc_illuminazione);
    setNomeFase(item.nome_fase_lunare);
  };

  const handleMouseOut = (item) => {
    setIsHovering(false);
    setGiorniSettimana([]);
    setDateDay([]);
    setPercIlluminazione([]);
    setNomeFase([]);
  };
  useEffect(() => {
    moonPhases();
  }, [lang]);

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      behavior: "smooth",
    });
  };

  const getPageFromUrl = (changeLang, paramLang) => {
    localStorage.setItem("lang", paramLang);
    setActive(paramLang);
    let path = window.location.pathname;

    if (path === "/") {
      navigate(changeLang);
      setLang(paramLang);
    }

    if (
      path.slice(0, 4) === "/it/" ||
      path.slice(0, 4) === "/si/" ||
      path.slice(0, 4) === "/en/"
    ) {
      navigate(changeLang + path.slice(4));
      setLang(paramLang);
    } else {
      navigate(changeLang + (path.slice(0, 1) !== "/" ? path : path.slice(1)));
      setLang(paramLang);
    }
  };

  const moonPhases = async () => {
    try {
      const response = await axios.get(Globals.GETMOONPHASES + "&lang=" + lang);
      listaMesi(response.data.lista_mesi);
      listaImmagini(response.data.immagini_lunari);
      listaFasi(response.data.fasi_lunari);
      setPercentuale(response.data.percentuale_giornaliera);
    } catch (err) {
      console.log("errore");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 500 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  return (
    <div className="App">
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav
        className={`navbar ${stickyClass}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="nav-container">
          <div>
            <NavLink to="/" className="nav-logo">
              <img className="logo-navbar" src={logo} alt="Logo Gravner"></img>
            </NavLink>
          </div>
          <div id="#lang">
            <ul className={click ? "nav-menu active" : "nav-menu text-center"}>
              <div
                className="resposive-lang"
                style={{
                  position: click ? "absolute" : "absolute",
                  top: "0",
                  left: "0",
                }}
              >
                <button
                  className={isActive === "it" ? "btn btn-lang-active" : "btn"}
                  onClick={() => getPageFromUrl("it/", "it")}
                >
                  Italiano
                </button>
                <button
                  className={isActive === "si" ? "btn btn-lang-active" : "btn"}
                  onClick={() => getPageFromUrl("si/", "si")}
                >
                  Slovensko
                </button>
                <button
                  className={isActive === "en" ? "btn btn-lang-active" : "btn"}
                  onClick={() => getPageFromUrl("en/", "en")}
                >
                  English{" "}
                </button>
              </div>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 115}
					  href="#slider"
					>
					  <NavLink
						to="/"
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						home
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#lang"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  // className='nav-links'
                  scroll={(el) => scrollWithOffset(el, 0)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#lang"}
                >
                  home
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 211}
					  href="#luna"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						la luna
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#luna"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#luna"}
                >
                  {Globals.language.navbar.moon[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#uomo"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						l'uomo
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#uomo"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#uomo"}
                >
                  {Globals.language.navbar.man[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#case"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						le case
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#case"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#case"}
                >
                  {Globals.language.navbar.houses[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#terra"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						la terra
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#terra"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#terra"}
                >
                  {Globals.language.navbar.earth[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#acqua"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						l'acqua
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#acqua"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#acqua"}
                >
                  {Globals.language.navbar.water[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#vendemmie"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						le vendemmie
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#vendemmie"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  location={{
                    pathname:
                      document.location.pathname + document.location.hash,
                  }}
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#vendemmie"}
                >
                  {Globals.language.navbar.harvests[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#cantina"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						la cantina
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#cantina"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  location={{
                    pathname:
                      document.location.pathname + document.location.hash,
                  }}
                  scroll={(el) => scrollWithOffset(el, 164)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#cantina"}
                >
                  {Globals.language.navbar.cellar[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                {/* <AnchorLink
					  className="link-custom-anchor"
					  offset={() => 163}
					  href="#vino"
					>
					  <NavLink
						activeclassname="active"
						className="nav-links"
						onClick={click ? handleClick : null}
					  >
						il vino
					  </NavLink>
					</AnchorLink> */}
                <HashLink
                  className={
                    `${location.pathname}${location.hash}` === "/#vino"
                      ? "nav-links active"
                      : "nav-links"
                  }
                  scroll={(el) => scrollWithOffset(el, 214)}
                  onClick={click ? handleClick : null}
                  smooth
                  to={"/#vino"}
                >
                  {Globals.language.navbar.wine[lang]}
                </HashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  to="/contatti"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  {Globals.language.navbar.contactUs[lang]}
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  to="/incontrarci"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  {Globals.language.navbar.meetUs[lang]}
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  to="/punti-g"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  {Globals.language.navbar.gpoint[lang]}
                </NavHashLink>
              </li>
            </ul>
          </div>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-close" : "fa fa-bars"}></i>
          </div>
        </div>
        <div
          className="container-fluid position-relative mt-4 text-center text-light fase-lunare"
          style={{ height: "100px", backgroundColor: "#000" }}
        >
          <div className="d-flex w-100 justify-content-center align-items-center ">
            {fasi.map((item, key) => (
              <div
                key={key}
                className="moon_phase_element"
                style={{ height: item.pixels + "px", width: percentuale + "%" }}
                onMouseOver={() => handleMouseOver(item)}
                onMouseOut={() => handleMouseOut(item)}
              >
                <div
                  style={{
                    width: "1px",
                    height: "100%",
                    borderRight: "1px solid" + item.color,
                  }}
                ></div>
                <div>
                  <input
                    type="hidden"
                    className="hidden_week_day"
                    value={item.week_day}
                  />
                  <input
                    type="hidden"
                    className="hidden_date_day"
                    value={item.date_day}
                  />
                  <input
                    type="hidden"
                    className="hidden_perc_illuminazione"
                    value={item.perc_illuminazione}
                  />
                  <input
                    type="hidden"
                    className="hidden_nome_fase_lunare"
                    value={item.nome_fase_lunare}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex w-100 justify-content-center ">
            {immagini.map((item, key) => (
              <div
                key={key}
                className="moon_image_element"
                style={{
                  width: percentuale + "%",
                  color: "#fff",
                  height: "20px",
                }}
              >
                <img
                  src={parseInt(item.fase) !== 1 ? luna1 : luna2}
                  alt="Fasi delle lune"
                  style={{
                    display: item.display_fase,
                    marginLeft: "-7px",
                    width: "15px",
                    maxWidth: "15px",
                    overflow: "visible",
                  }}
                />
              </div>
            ))}
          </div>
          <div
            className="d-flex justify-content-center font-resp-mounth"
            style={{ width: "100%" }}
          >
            {mesi.map((item, key) => (
              <div
                key={key}
                className="moon_month_element"
                style={{
                  width: item.percentuale_mese + "%",
                  borderRight: "1px solid #fff",
                  borderLeft: "1px solid #fff",
                  display: item.display_mese,
                }}
              >
                <span>{item.nome_mese}</span>
                <input
                  type="hidden"
                  value={item.percentuale_mese}
                  className="moon_month_element_percentage"
                />
              </div>
            ))}
          </div>
          {isHovering && (
            <div
              className="mx-1 position-responsive-information-moon"
              style={{
                position: "absolute",
                top: "-100px",
                right: "0",
                backgroundColor: "#323232",
                width: "350px",
              }}
            >
              <div>
                <div className="d-flex justify-content-center">
                  <div>
                    <button
                      className="btn-closed"
                      onClick={() => setIsHovering(false)}
                    >
                      X
                    </button>
                  </div>
                  <div className="col-6" style={{ textTransform: "uppercase" }}>
                    {giorniSettimana}
                  </div>

                  <div className="col-6">{dateDay}</div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-6">
                    {Globals.language.navbar.lighting[lang]}
                  </div>
                  <div className="col-6" id="percentuale_illuminazione">
                    {percIlluminazione}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-6">
                    {Globals.language.navbar.moonphase[lang]}
                  </div>
                  <div className="col-6" id="nome_fase_lunare">
                    {nomeFase}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
  // }
}

export default Navbar;
