import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../scss/slideshow.scss";
import Carousel from "react-bootstrap/Carousel";

import Globals from "../parameter/globals";

function Slideshow(props) {
	return (
		<div className='slideshow' id='slider'>
			<Carousel controls={false} fade={true} indicators={false} pause={false}>
				{props.images.map((item, key) => {
					return (
						<Carousel.Item>
							<div className='bg-image-wrap' key={key}>
								<img
									className='img-fluid image-card-carousel'
									src={Globals.domain + item.path_immagine}
									alt='Vino in anfora'
								/>
							</div>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</div>
	);
	// }
}

export default Slideshow;
