import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/footer.scss";
import Globals from "../parameter/globals";
import GeneralContext from "../context/GeneralContext";
import axios from "axios";
import { Button } from "react-bootstrap";


function Footer(props) {
  const { lang } = useContext(GeneralContext);

  let modifyCookie = props.modifyCookie || [];
  const [activeCookiebar, setActiveCookiebar] = useState(0);

  const getCookiebar = async () => {
    try {
      const response = await axios.get(Globals.GETFOOTERCOOKIE);
      setActiveCookiebar(parseInt(response.data.active_cookie[0].cookie_bar));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCookiebar();
  }, []);

  return (
    <div className="App mt-5" id="vino">
      <div className="footer-dark">
        <footer className="position-footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-3 item">
                <h3 className="title-service">
                  {Globals.language.footer.company[lang]}
                </h3>
                <ul>
                  <li>
                    <Link to="/term-of-use">
                      {Globals.language.footer.termOfUse[lang]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy">
                      {Globals.language.footer.privacy[lang]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/credits">
                      {Globals.language.footer.credits[lang]}
                    </Link>
                  </li>
                  <li>
                    <a href={Globals.domain}>
                      {Globals.language.footer.login[lang]}
                    </a>
                  </li>
                  <li>
                    {activeCookiebar !== 0 ? (
                      <>
                        <Button
                          className="mx-2 btn btn-link p-0 bg-transparent text-dark"
                          style={{ fontSize: "14px", textDecoration: "none" }}
                          onClick={modifyCookie}
                        >
                          {Globals.language.footer.cookie[lang]}
                        </Button>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {/* <a href='#'>{Globals.language.footer.cookie[lang]}</a> */}
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 item">
                <h3 className="title-service">
                  {Globals.language.footer.pages[lang]}
                </h3>
                <ul>
                  <li>
                    <Link to="/contatti">
                      {Globals.language.footer.contactUs[lang]}
                    </Link>
                  </li>
                  <li>
                    <li>
                      <Link to="/punti-g">
                        {Globals.language.footer.gpoint[lang]}
                      </Link>
                    </li>
                  </li>
                  <li>
                    <Link to="/incontrarci">
                      {Globals.language.footer.meetUs[lang]}
                    </Link>
                  </li>
                  {/* <li>
										<a href='#'>{Globals.language.footer.news[lang]}</a>
									</li> */}
                </ul>
                
              </div>
              <div className="col-md-6 item text">
                <h3 className="title-service">
                  Azienda agricola Gravner Francesco
                </h3>
                <p>
                  Loc. Lenzuolo Bianco, 9 - Oslavia 34170 Gorizia (GO) - Tel.
                  0481 30882 - info@gravner.it - P.IVA
                  00164170318{" "}
                </p>
              </div>
              {/* <div className="col item social"><a href="#"><i className="icon ion-social-facebook"></i></a><a href="#"><i className="icon ion-social-twitter"></i></a><a href="#"><i className="icon ion-social-snapchat"></i></a><a href="#"><i className="icon ion-social-instagram"></i></a></div> */}
            </div>
            {/* <div className='text-center'></div>
						<p className='copyright bg-light'>
							Copyright © 2023 <strong>Gravner.</strong>
						</p> */}
          </div>
          {/* <p>
            <a href="https://www.readynet.it/" target="_blank" rel="noreferrer">
              Powered by Ready Net
            </a>{" "}
          </p> */}
        </footer>

        {/* <div className="adminActions">
          <input type="checkbox" name="adminToggle" className="adminToggle" />
          <a className="adminButton" href="#!">
            <i className="fa fa-plus"></i>
          </a>

          <div className="adminButtons">
            <Link to="/contatti" title="Contattaci">
              <i className="fa fa-envelope"></i>
            </Link>

            <Link to="/incontrarci" title="Incontriamoci">
              <i className="fa fa-map-marker"></i>
            </Link>
            <Link to="/puntiG" title="Punti G">
              <i className="fa fa-users"></i>
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
  // }
}

export default Footer;
