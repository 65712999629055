import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Globals from "../parameter/globals";
import Form from "react-bootstrap/Form";
import Loading from "../components/Loading";
import { Card, Button } from "react-bootstrap";
import GeneralContext from "../context/GeneralContext";
import { Helmet } from "react-helmet";

import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaV2,
} from "react-recaptcha-x";

function MeetUs() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [dateMeeting, setDateMeeting] = useState(""); //select
  const [timeSchedule, setTimeSchedule] = useState("Mattina"); //select
  const [language, setLanguage] = useState(""); //select
  const [numPeople, setNumPeople] = useState(2);

  const [token, setToken] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [active, setActive] = useState(0);

  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const { lang } = useContext(GeneralContext);
  const [introText, setIntroText] = useState({});
  const parse = require("html-react-parser");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (token === "") {
      setMessageError(Globals.language.errorLabel.acceptRecaptcha[lang]);
      return false;
    }

    try {
      setLoading(true);
      let body = {
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        message: message,
        dateMeeting: dateMeeting,
        timeSchedule: timeSchedule,
        language: language,
        numPeople: numPeople,

        privacy: privacy,
        token: token,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(
        Globals.MEETUSCONTACT + "&lang=" + lang,
        formdata,
        {
          withCredentials: true,
        }
      );
      // TODO: remove console.logs before deployment
      // console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response))

      if (response?.data?.error === true) {
        setError(true);
        setMessageError(response.data.message);

        setLoading(false);
        setSuccess(false);
      } else {
        //clear state and controlled inputs
        setError(true);
        setLoading(false);
        setSuccess(true);
      }
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      console.log(err);
      setMessageError(Globals.language.errorLabel.serverError[lang]);
    }
  };

  const getTextData = async () => {
    try {
      const response = await axios.get(Globals.GETEXTMEETUS, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setIntroText(response?.data?.introtext);
      setActive(parseInt(response?.data?.activeform));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTextData();
  }, []);

  const v2Callback = (token) => {
    if (typeof token === "string") {
      setToken(token);
    } else if (typeof token === "boolean" && !token) {
      setMessageError(Globals.language.errorLabel.recaptchaExpired[lang]);
      setToken("");
    } else if (token instanceof Error) {
      setMessageError(Globals.language.errorLabel.serverError[lang]);
      setToken("");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.titles.meetUs[lang]}</title>
        <meta
          name="description"
          content={Globals.language.description.meetUs[lang]}
        />
        <link rel="canonical" href="https://www.gravner.it/" />
      </Helmet>

      {loading ? (
        <Loading />
      ) : success ? (
        <div
          className="p-2 incontraci-resp  text-center d-flex justify-content-center mx-auto align-items-center"
          style={{ minHeight: "520px" }}
        >
          {" "}
          <Card style={{ fontSize: "25px" }} className="p-2">
            <i
              className="fa fa-check-circle"
              style={{ fontSize: "50px", color: "green" }}
            ></i>
            {Globals.language.contacts.sendSuccess[lang]}
            <div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
          </Card>
        </div>
      ) : (
        <div className="incontraci-resp" style={{ minHeight: "1500px" }}>
          {introText?.htmlcontents_it !== undefined && active === 0 ? (
            <div className="mx-3">
              {parse(
                lang === "it"
                  ? introText?.htmlcontents_it
                  : lang === "en"
                  ? introText?.htmlcontents_en
                  : introText?.htmlcontents_si
              )}
            </div>
          ) : (
            ""
          )}
          {active === 1 && (
            <Form
              className="container"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {introText?.htmlcontents_it !== undefined
                ? parse(
                    lang === "it"
                      ? introText?.htmlcontents_it
                      : lang === "en"
                      ? introText?.htmlcontents_en
                      : introText?.htmlcontents_si
                  )
                : ""}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {Globals.language.contacts.nameForm[lang]}
                </Form.Label>
                <Form.Control
                  type="name"
                  placeholder={
                    Globals.language.contacts.nameFormPlaceholder[lang]
                  }
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {Globals.language.contacts.surnameForm[lang]}
                </Form.Label>
                <Form.Control
                  type="surname"
                  placeholder={
                    Globals.language.contacts.surnameFormPlaceholder[lang]
                  }
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {Globals.language.contacts.emailForm[lang]}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={
                    Globals.language.contacts.emailFormPlaceholder[lang]
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {Globals.language.contacts.phoneForm[lang]}
                </Form.Label>
                <Form.Control
                  type="phone"
                  placeholder={
                    Globals.language.contacts.phoneFormPlaceholder[lang]
                  }
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{Globals.language.contacts.data[lang]}</Form.Label>
                <Form.Control
                  type="DATE"
                  placeholder={
                    Globals.language.contacts.phoneFormPlaceholder[lang]
                  }
                  value={dateMeeting}
                  onChange={(e) => setDateMeeting(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  {Globals.language.contacts.timeSlot[lang]}
                </Form.Label>
                <Form.Select onChange={(e) => setTimeSchedule(e.target.value)}>
                  <option value={"Mattina"}>
                    {Globals.language.contacts.morning[lang]}
                  </option>
                  <option value={"Pomeriggio"}>
                    {Globals.language.contacts.afternoon[lang]}
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  {Globals.language.contacts.languagePreference[lang]}
                </Form.Label>

                <Form.Control
                  type="text"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  {Globals.language.contacts.visitorsNumber[lang]}
                </Form.Label>
                <Form.Select onChange={(e) => setNumPeople(e.target.value)}>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>
                  {Globals.language.contacts.messaggeForm[lang]}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={
                    Globals.language.contacts.messaggeFormPlaceholder[lang]
                  }
                  as="textarea"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  onChange={(e) =>
                    e.target.value === "on"
                      ? setPrivacy(true)
                      : setPrivacy(false)
                  }
                  required
                />
                <Form.Label htmlFor="formBasicCheckbox" className="mx-2">
                  {Globals.language.contacts.privacyForm[lang]}{" "}
                  <a href={"/contact-term-of-use"}>
                    {" "}
                    {Globals.language.contacts.privacyLabel[lang]}{" "}
                  </a>
                </Form.Label>
              </Form.Group>

              <Form.Group
                className="position-relative"
                controlId="formBasicCheckbox"
              >
                <ReCaptchaV2
                  callback={v2Callback}
                  theme={EReCaptchaV2Theme.Light}
                  size={EReCaptchaV2Size.Normal}
                  id="my-id"
                  data-test-id="my-test-id"
                  tabindex={0}
                />
              </Form.Group>

              <Button
                variant="danger"
                className="d-flex justify-content-center mx-auto my-2 w-25"
                type="submit"
              >
                <span style={{ fontSize: "25px" }}>
                  {Globals.language.contacts.sendButtonForm[lang]}
                </span>
              </Button>
              {messageError && <p> {messageError} </p>}
            </Form>
          )}
        </div>
      )}
    </>
  );
}

export default MeetUs;
